/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px; /* Adjust the height as needed */
    background-color: #0a0a0a; /* Light background color */
    color: #c5c5c5;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .logo {
    font-size: 20px;
    font-weight: bold;
  }
  
  .site-name {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
  }
  
  .menu-icon {
    font-size: 30px;
    cursor: pointer;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .header {

      height: auto; /* Adjust height to auto when stacked */
      padding: 10px 0; /* Add some padding */
    }
  
    .logo {
      font-size: 18px; /* Slightly smaller logo on smaller screens */
      margin-bottom: 10px; /* Space between logo and site name */
    }
  
    .site-name {
      font-size: 20px; /* Smaller site name */
      margin-bottom: 10px; /* Space between site name and menu */
    }
  
    .menu-icon {
      font-size: 30px; /* Larger menu icon on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .menu-icon {
      font-size: 35px; /* Increase menu icon size for very small screens */
    }
  
    .site-name {
      font-size: 18px; /* Even smaller site name */
    }
  
    .logo {
      font-size: 16px; /* Adjust logo size */
    }
  }